import Head from 'next/head'
import { useVenueContext } from '@/services/venue'
import { truly } from 'shared/utils/types'
import { useAppContext } from '@/pages/_app'

export type PageMetaProps = {
  subTitle?: string
  description?: string
  imageUrl?: string
  noIndex?: boolean
}

export function PageMeta(props: PageMetaProps) {
  const { t } = useAppContext()
  const venueContext = useVenueContext()
  const venue = venueContext?.venue

  const title = (() => {
    const mainTitle = venue?.features.meta?.title ?? venue?.title
    const titleFragments = [mainTitle, props.subTitle].filter(truly)
    return titleFragments.join(' - ') || 'Foodeon'
  })()

  const description =
    props.description ||
    venue?.features.meta?.description ||
    t('common:defaultStoreMetaDescription', { venueName: venue?.title })

  const imageUrl = props.imageUrl ?? venue?.headerPictureUrl
  const keywords = venue?.features.meta?.keywords

  return (
    <Head>
      <title>{title}</title>
      {props.noIndex && <meta name="robots" content="noindex" />}
      <meta name="description" content={description} />
      {props.description && <meta name="og:description" content={props.description} />}
      <meta name="og:title" content={title} />
      {imageUrl && <meta name="og:image" content={imageUrl} />}
      {keywords && <meta name="keywords" content={keywords} />}
    </Head>
  )
}
