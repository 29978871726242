export function CrossSm() {
  return (
    <svg
      fill="none"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      className="stroke-primary dark:stroke-primaryDark"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 1L1 14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 1L14 14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
