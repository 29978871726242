import { sessionStorage } from '@/utils/env'
import { fromEvent } from 'rxjs'
import { debounceTime, takeWhile } from 'rxjs/operators'
import { useVenueContext } from '@/services/venue'
import { useStateFromUiEvents, uiEvents, useDefaultObservableAsRef } from '../utils/event'

declare global {
  interface AppUIEventMap {
    ScrollPositionChange: {
      type: 'ScrollPositionChange'
      y: number
    }
    ScrollPositionChosenProduct: {
      type: 'ScrollPositionChosenProduct'
      product: {
        productGroupName: string
        productName: string
      } | null
    }
    ScrollPositionChosenGroup: {
      type: 'ScrollPositionChosenGroup'
      productGroup: {
        productGroupName: string
      } | null
    }
  }
}

export function useScrollPosition() {
  const venueContext = useVenueContext()!
  const { venueName, admin } = venueContext

  const scrollPositionId = `scroll-position-${venueName}`

  const scrollPosition$ = useStateFromUiEvents<number>({
    id: scrollPositionId,
    startingValue: 0,
    storage: sessionStorage,
    scan: (currentState, event) => {
      switch (event.type) {
        case 'ScrollPositionChange':
          return event.y
        default:
          return currentState
      }
    },
  })

  const { chosenProduct$ } = useChosenProduct(venueName)
  const { chosenProductGroup$ } = useChosenProductGroup(venueName)

  const scrollPosition = useDefaultObservableAsRef(scrollPosition$)
  const chosenProduct = useDefaultObservableAsRef(chosenProduct$)
  const chosenProductGroup = useDefaultObservableAsRef(chosenProductGroup$)

  const startTracking = () => {
    const originalUrl = document.location.href
    const pageHasChanged = () => {
      return originalUrl === document.location.href
    }
    const scroll$ = fromEvent(window, 'scroll').pipe(debounceTime(100), takeWhile(pageHasChanged))

    scroll$.subscribe(() => {
      uiEvents.next({
        type: 'ScrollPositionChange',
        y: window.scrollY,
      })
    })
  }

  const tryRestore = () => {
    if (chosenProductGroup.current) {
      const productGroupWrapper = document.getElementById(
        `pg-${chosenProductGroup.current.productGroupName}`,
      )

      if (productGroupWrapper) {
        // admins have an extra admin bar above the product block
        const extraTopOffset = admin.isAdmin ? 150 : 100
        window.scrollTo(0, productGroupWrapper.offsetTop - extraTopOffset)
        uiEvents.next({
          type: 'ScrollPositionChosenGroup',
          productGroup: null,
        })
        return true
      }
    }

    if (chosenProduct.current) {
      const productWrapper = document.getElementById(
        `product-${chosenProduct.current.productGroupName}-${chosenProduct.current.productName}`,
      )

      if (productWrapper) {
        // admins have an extra admin bar above the product block
        const extraTopOffset = admin.isAdmin ? 150 : 100
        window.scrollTo(0, productWrapper.offsetTop - extraTopOffset)
        uiEvents.next({
          type: 'ScrollPositionChosenProduct',
          product: null,
        })
        return true
      }
    }

    if (scrollPosition.current) {
      window.scrollTo(0, Number(scrollPosition.current))
      return true
    }

    return false
  }

  return { startTracking, tryRestore }
}

export function useChosenProduct(venueName: string) {
  const chosenProductId = `chosen-product-${venueName}`
  type ChosenProduct = { productGroupName: string; productName: string }
  const chosenProduct$ = useStateFromUiEvents<null | ChosenProduct>({
    id: chosenProductId,
    startingValue: null,
    storage: sessionStorage,
    process: (source, scan) => {
      return source.pipe(
        scan((currentState, event) => {
          switch (event.type) {
            case 'ScrollPositionChosenProduct':
              if (!event.product) {
                return null
              }
              const { productGroupName, productName } = event.product
              return { productGroupName, productName }
            default:
              return currentState
          }
        }),
      )
    },
  })

  return { chosenProduct$ }
}

export function useChosenProductGroup(venueName: string) {
  const chosenProductGroupId = `chosen-product-group-${venueName}`
  type chosenProductGroup = { productGroupName: string }
  const chosenProductGroup$ = useStateFromUiEvents<null | chosenProductGroup>({
    id: chosenProductGroupId,
    startingValue: null,
    storage: sessionStorage,
    process: (source, scan) => {
      return source.pipe(
        scan((currentState, event) => {
          switch (event.type) {
            case 'ScrollPositionChosenGroup':
              if (!event.productGroup) {
                return null
              }
              const { productGroupName } = event.productGroup
              return { productGroupName }
            default:
              return currentState
          }
        }),
      )
    },
  })

  return { chosenProductGroup$ }
}
