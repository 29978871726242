import { currencySigns } from './currency-signs'

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function formatPrice(
  input: number,
  params: { language: string; currency: string; useGrouping?: boolean },
) {
  const numberFormat = (() => {
    const numberFormatOptions = {
      style: 'currency',
      currency: params.currency,
      currencyDisplay: 'symbol',
      useGrouping: !!params.useGrouping,
      minimumFractionDigits: Number.isInteger(input) ? 0 : 2,
    }
    try {
      return new Intl.NumberFormat(params.language, numberFormatOptions)
    } catch (e) {
      // in case NumberFormat couldn't use the provided params.language
      if (e instanceof RangeError) {
        return new Intl.NumberFormat('ru', numberFormatOptions)
      }

      throw e
    }
  })()

  const formattedPrice = numberFormat.format(input)

  const customFormatters: Record<string, (amount: string) => string> = {
    PEN: (amount) => {
      return `S/. ${amount}`
    },
    CAD: (amount) => {
      return `\$${amount}`
    },
    AUD: (amount) => {
      return `\$${amount}`
    },
    TTD: (amount) => {
      return `\$${amount}`
    },
    BYN: (amount) => {
      return `${amount} р.`
    },
    CPN: (amount) => {
      return `${amount} CPN`
    },
  }

  const customFormatter = customFormatters[params.currency]
  if (customFormatter) {
    const amountFormatted = new Intl.NumberFormat(params.language, {
      style: 'decimal',
      useGrouping: !!params.useGrouping,
      minimumFractionDigits: Number.isInteger(input) ? 0 : 2,
    }).format(input)
    return customFormatter(amountFormatted)
  }

  const currencySign = currencySigns[params.currency as keyof typeof currencySigns]
  const replacements = [
    {
      from: ['грн.'],
      to: '₴',
    },
    {
      from: ['руб.'],
      to: '₽',
    },
    ...(currencySigns
      ? [
          {
            from: [params.currency],
            to: currencySign,
          },
        ]
      : []),
  ]

  return replacements
    .flatMap((r) => {
      return r.from.map((from) => {
        return { from, to: r.to }
      })
    })
    .reduce((acc, cur) => {
      return acc.replace(cur.from, cur.to)
    }, formattedPrice)
}

export function nullOr<T>(input: string, mapFn: (input: string) => T) {
  if (input.length === 0) {
    return null
  }

  return mapFn(input)
}
